body {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

/* Navbar Styles */

.navbar {
  padding-top: 1rem !important;
  background-color: #252b33 !important;
}

.nav-border-img {
  transform: translateY(-10px);
}

/* .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241, 234, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  } */

.navbar-toggler {
  border-color: #f7555c !important;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.navbrand-text {
  color: #f7555c;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145.5%; /* 2.1825rem */
  text-transform: uppercase;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .navbrand-text {
    font-size: 0.8rem;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(247, 85, 92, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.buttons-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 991.98px) {
  .buttons-div {
    flex-direction: column;
    margin-top: 1rem;
  }
}

.btn-wrapper {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f1ea00;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.btn-text-reverse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #081522 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}

/* Navbar Styles End Here */

/* Hero Section Styles */

.hero-section {
  margin-top: 8rem;
}

.first-col {
  position: relative;
  width: 338px;
}

@media (max-width: 1199.98px) {
  .first-col {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.text-wrapper {
  position: absolute;
  top: 30px;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.bird-text {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.hero-detail-text {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 2rem;
  line-height: 24px;
}

@media (max-width: 1199.98px) {
  .hero-main-img {
    margin-top: 4rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.third-col-wrapper {
  position: relative;
}

@media (max-width: 1199.98px) {
  .third-col-wrapper {
    margin-top: 4rem;
  }
}

.chat-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 2rem 3rem 2rem 2rem;
}

.message-wrapper {
  max-height: 400px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.main-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.sender-name {
  color: #f7555c;
  font-size: 8.959px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 20.113px */
  text-transform: uppercase;
}

.message-text {
  color: #f0f463;
  font-size: 8.959px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.335px; /* 160% */
  text-transform: capitalize;
  margin: 0;
}

.main-message .message-wrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.message-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.message-wrapper::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 5px;
}

/* Handle on hover */
.message-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.user-message {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 1rem;
}

.user-message > span {
  color: #f1ea00 !important;
  margin-right: 10px;
}

.form-container {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.form-control {
  background-color: transparent !important;
  border: 1px solid #686a70 !important;
  color: #fff !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.form-control ::placeholder {
  color: #fff !important;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.input-group-text {
  color: #fff;
  text-align: center;
  font-size: 8.642px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #686a70 !important;
  border-left: none;
}

/* Hero Styles End Here */

/* Leaderboard Styles */

.leaderboard-wrapper {
}

.leaderboard-header {
  color: #f7555c;
  font-size: 25.877px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
}

.leaderboard-subtext {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.table-div {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 860px !important;
  background: linear-gradient(180deg, #191a1c 0%, rgba(25, 26, 28, 0) 100%);
  border: 1px solid #f1ea00 !important;
}

@media (max-width: 1199.98px) {
  .table {
    width: 100% !important;
  }
}

.table > :not(caption) > * > * {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 1rem 0.5rem !important;
}

.table-top-header {
  color: #f1ea00 !important;
}

.text-center {
  text-align: center;
}

/* Leaderboard Styles End Here */

/* Projects Page Styles */

.projects-wrapper {
  /* background-image: url("./assets//projectsimg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
}

.card-wrapper {
  margin-top: 6rem;
}

.main-card {
  position: relative;
  width: 100%;
  height: 320px;
  border-radius: 10px;
  margin-top: 2rem;
}

.card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #191a1c !important;
  z-index: 3 !important;
}

.border-div {
  position: absolute;
  bottom: -6px;
  border-radius: 10px;
  background: var(--f, linear-gradient(180deg, #a2fd16 0%, #f1ea00 100%));
  height: 10px;
  width: 95%;
  left: 10px;
  z-index: 2 !important;
}

.game-name {
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 6rem;
}

.card-btn-wrapper {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.new-card-btn-wrapper {
  margin-top: 3rem;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.card-btn-wrapper > svg {
  cursor: pointer;
}

.play-text {
  color: #f1ea00;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  cursor: pointer;
}

.create-text {
  color: #f1ea00;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  cursor: pointer;
  width: 120px;
}

.footer-text {
  text-align: center;
  color: #f1ea00;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 4rem;
}

/* Projects Styles End Here */

/* SignUp Styles   */

.signup-container {
  width: 400px;
  margin-top: 8rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: black;
  border: 1px solid white;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  padding-bottom: 2rem;
}

.svg-class {
  position: absolute;
}

.test {
  font-size: 14px;
  border: 10px solid black;
  padding: 0rem 1rem;
  min-height: 3em;
  resize: both;
  background: transparent;
  color: yellow;
  /* border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23A2FD16' /%3E%3Cstop offset='25%25' stop-color='%23F1EA00' /%3E%3Cstop offset='50%25' stop-color='%23A2FD16' /%3E%3Cstop offset='100%25' stop-color='%23F1EA00' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='2' stroke-dasharray='388'/%3E %3C/svg%3E")
      1; */
}

@media (max-width: 575.98px) {
  .signup-container {
    width: 100%;
  }
}

.signup-header {
  text-align: center;
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.form-wrapper {
  margin-top: -1rem;
}
.iframe-div {
  position: relative;
  min-height: 465px;
}

label {
  color: white;
  font-size: 16px;
}

.signup-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  position: relative;
  width: 153px;
  height: 33px;
  margin-left: auto;
  margin-right: auto;
}

.signup-btn-wrapper > svg {
  cursor: pointer;
}

.signup-btn-text {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid white;
  padding: 0.5rem 2rem;
  border-radius: 10px;
}

.signup-btn-text:hover {
  background-color: white;
  color: black;
}

.footer {
  width: 100% !important;
  padding-bottom: 1rem;
  margin-top: 2rem !important;
}
.maintance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.game-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  padding-bottom: 1rem;
  border-top: 2px solid #f1ea00;
  padding-top: 20px;
  margin-top: 2rem !important;
}

@media (max-width: 1399.98px) {
  .game-footer {
    position: static;
  }
}

.footer-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 990px) {
  .footer-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.icon-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.find-us-text {
  color: #f7555c;
  text-align: left;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145.5%; /* 2.1825rem */
  text-transform: uppercase;
  flex: 1;
}

.icon {
  font-size: 32px;
  color: #fff;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.icon:hover {
  scale: 1.2;
  transition: all 300ms ease-in-out;
}

.contact-us-text {
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
}

.email-text {
  color: #f0f463;
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 1.40313rem */
  text-transform: lowercase;
}

.contact-text {
  color: #f0f463;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 1.96438rem */
  text-decoration-line: underline;
  text-transform: uppercase;
  margin-bottom: 0rem;
}

.find-text {
  color: #f7555c;
  text-align: right;
  font-size: 0.8125rem;
  margin-right: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 1.82406rem */
  text-transform: lowercase;
  margin-bottom: 0rem;
}

.email-text:hover {
  color: #f1ea00;
}

.refresh-icon {
  margin-left: 1rem;
  cursor: pointer;
}

@media (max-width: 1199.98px) {
  .refresh-icon {
    margin-left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
}

.modal-content {
  background-color: #070708 !important;
  border: 1px solid #f1ea00 !important;
  border-radius: 10px !important;
  position: relative;
  padding-bottom: 2rem;
}

.close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: -15px;
  right: 10px;
  z-index: 200;
}

.close > span {
  color: #f1ea00;
  font-size: 48px;
}

.send-mail-text {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
}

.modal-form-container {
  margin-top: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.modal-form-control {
  background-color: transparent !important;
  border: 1px solid #686a70 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.modal-form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.modal-input-group-text {
  background: var(
    --f,
    linear-gradient(180deg, #a2fd16 0%, #f1ea00 100%)
  ) !important;
  cursor: pointer;
  color: #081522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.refresh-div {
  gap: 0.5rem;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .refresh-div {
    gap: 0;
    margin-top: 0;
  }
}

.refresh-text {
  margin: 0;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .refresh-text {
    margin-top: 1rem;
  }
}

/* Pepe-Tournament-Styles */

.tournament-wrapper {
  margin-top: 6rem;
  padding-bottom: 4rem;
}

.tournament-header {
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.tabs-wrapper {
  margin-top: 2.5rem;
  width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (max-width: 767.98px) {
  .tabs-wrapper {
    width: 100%;
  }
  .iframeDiv {
    margin-left: -7px !important;
  }
}

.tabs-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs-text {
  color: rgba(255, 255, 255, 0.76);
  font-size: 41.28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.tabs-text.active {
  font-size: 41.28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  background: var(--f, linear-gradient(180deg, #a2fd16 0%, #f1ea00 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 767.98px) {
  .tabs-text {
    font-size: 24.28px;
  }
}

@media (max-width: 767.98px) {
  .tabs-text.active {
    font-size: 24.28px;
  }
}

.upcoming-content {
  margin-top: 2rem;
}

.tournament-card {
  position: relative;
  width: 359px;
  height: 431px;
  margin-top: 2rem;
}

.tournament-card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.card-bird-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.tournament-card-header {
  color: #f7555c;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 35.92px */
  text-transform: uppercase;
  margin-top: 1rem;
}

.tournament-card-number {
  margin-top: 0.5rem;
  color: #f0f463;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 26.94px */
  text-transform: uppercase;
}

.tournament-card-info-div {
  margin-top: 2rem;
}

.info-text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #686a70;
  margin-top: 1.5rem;
}

.ended-info-text-h2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #f7555c;
  margin: 0;
}
.info-text-h2 {
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #f7555c;
  margin: 0;
}

.ended-info-text-p {
  color: #f0f463;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.info-text-p {
  color: #f0f463;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.tournament-card-btns-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.8rem;
}

.ended-tournament-card-btns-div {
  position: absolute;
  bottom: 40px;
  left: 00px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.8rem;
  width: 100%;
}

.tournament-card-btns {
  position: relative;
  width: 110.45px;
  height: 25.801px;
  cursor: pointer;
}
.ended-tournament-card-btns {
  position: relative;
  width: 136.815px;
  height: 33.199px;
  cursor: pointer;
}

.tournament-card-btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  color: #f0f463;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 17.96px */
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  margin-top: 2px;
}
.ended-tournament-card-btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  color: #fff;
  text-align: center;
  font-size: 9.91px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 22.247px */
  text-transform: uppercase;
  cursor: pointer;
}

.second-btn {
  color: #fff;
}

.ended-registration-fee-text {
  color: #f0f463;
  text-align: center;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 8.98px */
  text-transform: uppercase;
  margin-top: 15px;
}

.ended-registration-fee-text::before {
  content: "•";
  color: #f1ea00;
}

.registration-fee-text {
  color: #f0f463;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 8.98px */
  text-transform: uppercase;
  margin-top: 2rem;
  text-align-last: center;
}
.registration-fee-text::before {
  content: "•";
  color: #f1ea00;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.box {
  background-color: #a2fd16;
}

.tabs-border-img {
  width: 500px;
  margin-top: 1rem;
}

@media (max-width: 767.98px) {
  .tabs-border-img {
    width: 100%;
  }
}

.tour-ended-text {
  color: #f7555c;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
}

.winners-text {
  position: absolute;
  bottom: 0px;
  left: 30px;
}

.leaderboard-btn {
  margin-top: 1rem;
}

.tournamentCardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1399.98px) {
  .tournamentCardContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1199.98px) {
  .tournamentCardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .tournamentCardContainer {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
}

.tab-content {
  margin-top: 2rem;
}

/* dashboard */

.dashboard-wrapper {
  margin-top: 4rem;
  padding-bottom: 6rem;
}

.dashboard-header {
  color: #f7555c;
  font-size: 39px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}

.search-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 4rem;
}

@media (max-width: 992.98px) {
  .search-wrapper {
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .search-wrapper {
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
}

.search-bar {
  position: relative;
  width: 378px;
  height: 30px;
}

@media (max-width: 767.98px) {
  .search-bar {
    width: 320px;
  }
}

@media (max-width: 767.98px) {
  .search-bar > svg {
    width: 320px;
  }
}

.search-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding-left: 2rem;
  padding-right: 4rem;
  color: #686a70;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 20.205px */
  text-transform: uppercase;
}

.search-input:focus {
  background-color: transparent;
  border: none !important;
  box-shadow: none;
  outline: none;
}

.search-icon {
  position: absolute;
  top: 7px;
  right: 50px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.62);
}

.dashboard-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.dashboard-tab {
  cursor: pointer;
  color: #536a7d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 15.715px */
  text-transform: uppercase;
}

.selected {
  cursor: pointer;
  color: #f0f463;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 15.715px */
  text-transform: uppercase;
}

.dashboard-bottom-border {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 150px;
}

.dashboard-tab-content {
  margin-top: 2rem;
}

.dashboard-card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1399.98px) {
  .dashboard-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1199.98px) {
  .dashboard-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .dashboard-card-container {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
}

.dashboard-card {
  position: relative;
  width: 359px;
  height: 500px;
  margin-top: 4rem;
}

@media (max-width: 991.98px) {
  .dashboard-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.dashboard-card-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1rem 1.5rem;
}

.dashboard-card-green-bird {
  display: block;
  margin: 1.5rem auto 0 auto;
}

.dashboard-card-id {
  color: #f0f463;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 20.205px */
  text-transform: uppercase;
  margin-top: 1rem;
}

.dashboard-card-h2 {
  color: #f7555c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 31.43px */
  text-transform: uppercase;
}

.dashboard-card-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.dashboard-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.top-row {
  padding-bottom: 10px;
  border-bottom: 1px solid #686a70;
}
.bottom-row {
  padding-bottom: 10px;
  border-bottom: 1px solid #686a70;
}

.table-col {
  flex: 1;
}

.table-second-col {
  flex: 2;
}

.top-col {
  color: #f7555c;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 17.96px */
  text-transform: uppercase;
}

.bottom-col {
  color: #f0f463;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 17.96px */
  text-transform: uppercase;
}

.last-bottom-col {
  color: #f0f463;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 17.96px */
  text-transform: uppercase;
}

.your-score-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: -10px;
  padding: 0 1rem;
}

.ended-your-score-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.your-score-text {
  color: #f7555c;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 17.96px */
  text-transform: uppercase;
  margin: 0;
}

.dashboard-total-score {
  color: #f0f463;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 17.96px */
  text-transform: uppercase;
  margin: 0;
  margin-right: 1rem;
}

.dashboard-card-info-text {
  color: #f0f463;
  text-align: center;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 8.98px */
  text-transform: uppercase;
  margin-top: 1rem;
}

.dashboard-card-info-text::before {
  content: "•";
  margin-right: 8px;
  color: #f1ea00;
}

.dashboard-claim-btn {
  width: 110.45px;
  height: 26.801px;
  position: relative;
  margin-top: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.dashboard-claim-btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  color: #fff;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 17.96px */
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
}

@keyframes blur {
  0%,
  100% {
    filter: blur(0px);
  }
  50% {
    filter: blur(3px);
  }
}

.animated-svg {
  animation: blur 3s ease-in-out infinite;
}

.tournament-card-img {
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
}

.tournament-card-img.turned {
  transform: rotateY(180deg);
}

.user-nav-img {
  cursor: pointer;
  margin-left: 1.5rem;
}

.user-img-div {
  position: relative;
}

@media (max-width: 991.98px) {
  .user-img-div {
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.user-modal {
  position: absolute;
  top: 80px;
  left: -100px;
  width: 287px;
  border: 1px solid #f1ea00;
  z-index: 100;
  border-radius: 5px;
  padding: 1.5rem 2rem;
}

@media (max-width: 991.98px) {
  .user-nav-img {
    margin-top: 1rem;
  }
}

.user-modal-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.user-modal-dashboard-btn {
  position: relative;
  width: 153px;
  height: 33px;
  cursor: pointer;
}

.user-modal-btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  cursor: pointer;
  color: #081522;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.user-modal-btn-text-bottom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #f1ea00;
}

.kickstarter-admin-countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 991.98px) {
  .kickstarter-admin-countdown-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.kickstarter-admin-item {
  position: relative;
  margin: 0;
  text-align: center;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #f0f463;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 20.205px */
  text-transform: uppercase;
}

.kickstarter-admin-item > span {
  font-size: 16px;
  line-height: 24px;
  color: #868686;
  font-weight: 500;
  margin: 0;
  text-align: center;
  text-transform: capitalize !important;
  padding: 0 !important;
}

.colon-span {
  color: #f0f463;
}

.ant-picker-panel-container {
  background-color: #000 !important;
  border: 1px solid #f1ea00 !important;
}

.ant-picker-header-view button {
  color: #fff !important;
}

.ant-picker-header > button {
  color: #fff !important;
}

.ant-picker-content th {
  color: #fff !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  color: #fff !important;
}
.ant-picker-cell .ant-picker-cell-inner:hover {
  background-color: transparent !important;
}

.ant-picker-cell-in-view {
  color: #fff !important;
}
.ant-picker-cell .ant-picker-cell-inner:hover {
  background-color: transparent !important;
}

.ant-picker-today-btn {
  color: #f1ea00 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: 1px solid #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #fff !important;
  color: #000 !important;
}

.nav-text {
  color: #f0f463;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 224.5%; /* 1.68375rem */
  text-transform: uppercase;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.nav-text-div {
  display: flex;
  gap: 2rem;
}

@media (max-width: 1199.98px) {
  .nav-text-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}

.footer-border-img {
  transform: translateY(20px);
}

.modal-div {
  position: relative;
}

.menu-modal-div {
  position: absolute;
  top: 65px;
  right: 0px;
}

@media (max-width: 1199.98px) {
  .menu-modal-div {
    position: static;
    right: 0;
  }
}
@media (max-width: 1199.98px) {
  .profile-icon {
    display: block;
    margin: 0 auto;
  }
}

.contact-us-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
