body {
  background-color: #0099cc;
  color: #fff;
  margin: 0;
}

#screen canvas {
  margin: auto;
  /* Hide the gap for font descenders. */
  display: block;

  /* disable scaling interpolation */
  /* image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor; */
  height: 551px !important;
  /* width: 100%; */
  width: 100% !important;
  transform: translateX(-75px) !important;
}

@media (max-width: 1399.98px) {
  #screen canvas {
    width: 390px !important;
    transform: translateX(-25px) !important;
  }
}

@media (max-width: 1199.98px) {
  #screen canvas {
    transform: translateX(0px) !important;
    width: 100% !important;

    margin-top: 4rem !important;
  }
}

#share {
  margin: 10px auto;
  text-align: center;
}

#share .fb-share-button iframe {
  margin-top: -4px;
}

/* @font-face {
  font-family: "gamefont";
  src: url("data/css/gamefont.eot");
  src: url("data/css/gamefont.eot?#iefix") format("embedded-opentype"),
    url("data/css/gamefont.woff") format("woff"),
    url("data/css/gamefont.ttf") format("truetype"),
    url("data/css/gamefont.svg#gamefont") format("svg");
  font-weight: normal;
  font-style: normal;
} */

.google {
  position: absolute;
  left: 23%;
}
